import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal-helper"
export default class extends Controller {
  hideScrollbar() {
    document.body.classList.add('modal-open')
  }

  showScrollbar() {
    document.body.classList.remove('modal-open')
  }

  focusModal() {
    this.element.focus()
    this.element.classList.add('ia-outline-none')
  }
}
