import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

// Connects to data-controller="cookies-banner"
export default class extends Controller {
  allowCookies() {
    // Set cookie
    Cookies.set('cookies_accepted', 'true', { expires: 365 }) // sets a cookie for 1 year

    // Hide banner
    this.removeCookieBanner()

    // Tell backend (& ignore response for now)
    fetch('/allow-cookies', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=utf-8'
      }
    })
  }

  removeCookieBanner() {
    this.element.remove()
  }
}
